import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "../styles/DashboardLayout.css"; // Link to CSS file
import { FaDollarSign } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { IoGiftSharp, IoSettingsSharp } from "react-icons/io5";
import CarbModal from "./CarbModal";
import GiftModal from "./GiftModal";

export default function DashboardLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = window.location.pathname;
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showHelpDropdown, setShowHelpDropdown] = useState(false);

  useEffect(() => {
    const updateCurrentTime = () => setCurrentTime(new Date());
    const timer = setInterval(updateCurrentTime, 60000); // Update every minute
    return () => clearInterval(timer);
  }, []);

  const username = location.state?.username || "User";

  const links = [
    { path: "/overview", label: "News", color: "#8a9494" },
    { path: "/dumps", label: "Dumps", color: "#90cfe4" },
    { path: "/cvv", label: "CVV2", color: "#90e4c2" },
    { path: "/fullz", label: "Fullz", color: "#b0e4d0" },
    { path: "/wholesale", label: "Wholesale" },
    { path: "/cart", label: "Cart" },
    { path: "/orders", label: "Orders" },
    { path: "/auction", label: "Auction", color: "#d7422e", color2: "white" },
    { path: "/tools", label: "Tools" },
    { path: "/ticket", label: "Ticket" },
    { path: "/profile", icon: <IoSettingsSharp /> },
  ];

  const [carbModal, setCarbModal] = useState(false);
  const openCarb = () => setCarbModal(true);
  const closeCarb = () => setCarbModal(false);

  const [giftModal, setGiftModal] = useState(false);
  const openGift = () => setGiftModal(true);
  const closeGift = () => setGiftModal(false);

  return (
    <div className="dashboard-layout">
      {/* Greeting and Date Section */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="greeting-section">
          <p className="greeting">
            Welcome, <span style={{ color: "blue" }}>{username}</span>!
          </p>
          <p className="time">
            <strong>Balance: </strong>
            0.00 USD [{" "}
            <span
              onClick={() => navigate("/deposit")}
              style={{
                textDecoration: "underline",
                color: "blue",
              }}
            >
              {" "}
              add funds{" "}
            </span>
            ]
          </p>
          <p className="time">
            <strong>Carb Rating: 🦀 </strong>[{" "}
            <span
              onClick={openCarb}
              style={{
                textDecoration: "underline",
                color: "blue",
              }}
            >
              details
            </span>{" "}
            ]
          </p>
          <p className="time">
            <strong>Current server time: </strong>
            {moment(currentTime).format("hh:mm:ss A")}
          </p>
        </div>

        <div
          style={{
            marginTop: 15,
            width: "30%",
            justifyContent: "space-between",
          }}
        >
          <button
            className="nav-button"
            style={{ color: "green" }}
            onClick={() => navigate("/lottery")}
          >
            Lottery
            <FaDollarSign style={{ fontSize: 16, color: "green" }} />
          </button>
          <button className="nav-button" onClick={openGift}>
            <IoGiftSharp style={{ fontSize: 16, color: "red" }} />
            My Gift
          </button>
          <button
            className="nav-button"
            onClick={() => navigate("/")}
            style={{ alignItems: "center" }}
          >
            <MdOutlineLogout style={{ fontSize: 16 }} />
            Logout
          </button>
        </div>
      </div>

      <div className="dashboard-buttons-container">
        {/* Display links as buttons */}
        <nav className="button-links">
          {links.map((link) => (
            <button
              key={link.path}
              className={`nav-button ${
                currentPath === link.path ? "active" : ""
              }`}
              style={{ backgroundColor: link.color, color: link.color2 }}
              onClick={() => navigate(link.path)}
            >
              {link.label}
              {link.icon}
            </button>
          ))}

          {/* Help Dropdown */}
          <div className="dropdown-container">
            <button
              className="nav-button"
              onClick={() => setShowHelpDropdown(!showHelpDropdown)}
            >
              Help
            </button>
            {showHelpDropdown && (
              <div className="dropdown-menu">
                <button
                  className="dropdown-item"
                  onClick={() => navigate("/rules")}
                >
                  Rules
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => navigate("/faq")}
                >
                  FAQ
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => navigate("/education")}
                >
                  Education
                </button>
              </div>
            )}
          </div>
        </nav>
      </div>

      {/* Account Inactive Message */}
      <div className="inactive-account-message">
        <p>
          Your account is inactive. For activation you need to top up your
          balance.
        </p>
        <p>
          Attention: Not activated accounts for more than 5 days will be deleted
          automatically.
        </p>
      </div>

      {carbModal && <CarbModal closeModal={closeCarb} />}
      {giftModal && <GiftModal closeModal={closeGift} />}

      {/* Main content */}
      <main className="main-content">{children}</main>
      <p>© 2024 Crabs on Security</p>
    </div>
  );
}
