import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../styles/Login.css";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!username || !password) {
      toast.error("Please enter both username and password!");
      return;
    }

    // Proceed to the next screen if validation passes
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/cc/payment");
    }, 1000);
  };

  return (
    <div className="loginDiv1">
      <ToastContainer />
      <div className="loginDiv2">
        <div className="loginDiv2b">
          <div className="loginDiv21">
            <h1>BRAINSCLUB ADMIN</h1>
          </div>
          <div className="loginDiv22">
            <h3>USERNAME</h3>
            <div className="password-input-container">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <h3>PASSWORD</h3>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={handlePasswordToggle}
                className="password-toggle-icon"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="loginBtn"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? <h3>Authenticating...</h3> : <h3>Login</h3>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
