import React from "react";
import "../styles/Auction.css";
import DashboardLayout from "../components/DashboardLayout";

const Auction = () => {
  const rules = [
    "Dumps you're buying in auction are non-refundable. All bids and sales are final.",
    "Bid increment is the least amount by which a bid must be raised for the next bid.",
    "Dumps are sold subject to reserve price.",
    "Quantity of available dumps may change during the auction.",
    "Placing a bid will reserve money on your account balance until the auction ends.",
    "Bid cannot be cancelled.",
    "Auction starts 3 hours after daily released updates at 11 AM EST.",
    "Auction ends half hour before the daily released update at 7:30 AM EST.",
  ];

  const currentAuction = {
    eligibility: "Available to customers with 0 crabs rating and above",
    startTime: "July 24, 2022, 6 p.m.",
    endTime: "July 25, 2022, 2:30 p.m.",
  };

  return (
    <DashboardLayout>
      <div className="auction-container">
        {/* Auction Header */}
        <div className="auction-header">
          <h2>Auction Rules:</h2>
        </div>

        {/* Auction Rules */}
        <div className="auction-rules">
          <ol>
            {rules.map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ol>
        </div>

        {/* Current Auction Details */}
        <div className="current-auction">
          <h2>Current Auction:</h2>
          <p>
            <strong>Eligibility:</strong> {currentAuction.eligibility}
          </p>
          <p>
            <strong>Auction starts:</strong> {currentAuction.startTime}
          </p>
          <p>
            <strong>Auction ends:</strong> {currentAuction.endTime}
          </p>
        </div>

        {/* How to Place a Bid */}
        <div className="place-bid">
          <h2>How to Place a Bid?</h2>
          <ol>
            <li>Search for your bin.</li>
            <li>Enter the necessary quantity, depending on availability.</li>
            <li>Press the "Place bid" button.</li>
          </ol>
        </div>

        {/* User Bids Section */}
        <div className="my-bids">
          <h2>My Bids:</h2>
          <p>You don't have any active bids.</p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Auction;
