import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Dumps.css";
import fullzData from "../components/Cvv.json";
import Modal from "../components/Modal";

const Cvv = () => {
  const navigation = useNavigate();
  const [modal, setModal] = useState(false);
  const [shuffledData, setShuffledData] = useState([]);

  // Function to shuffle an array
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Shuffle the data when the component loads
  useEffect(() => {
    setShuffledData(shuffleArray(fullzData));
  }, []);

  const handleAddToCart = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    navigation("/deposit");
  };

  const renderTableRow = (item) => (
    <tr key={item.id} className="table-row">
      <td>{item.id}</td>
      <td>{item.type}</td>
      <td>{item.subtype}</td>
      <td>{item.expDate}</td>
      <td>{item.name}</td>
      <td>{item.country}</td>
      <td>{item.state}</td>
      <td>{item.zip}</td>
      <td>{item.fullz}</td>
      <td>{item.refund}</td>
      <td>{item.base}</td>
      <td>${item.price}</td>
      <td>
        <button
          onClick={() => handleAddToCart()}
          style={{
            marginTop: 10,
            padding: 7,
            alignItems: "center",
            backgroundColor: "green",
            color: "white",
            border: "none",
          }}
        >
          Add to Cart{" "}
          <AiOutlineShoppingCart
            style={{ alignSelf: "center", fontSize: 12 }}
          />
        </button>
      </td>
    </tr>
  );

  const renderCard = (item) => (
    <div key={item.id} className="card">
      <p>
        <strong>S/N:</strong> {item.id}
      </p>
      <p>
        <strong>Type:</strong> {item.type}
      </p>
      <p>
        <strong>Subtype:</strong> {item.subtype}
      </p>
      <p>
        <strong>Expire Date:</strong> {item.expDate}
      </p>
      <p>
        <strong>Name:</strong> {item.name}
      </p>
      <p>
        <strong>Country:</strong> {item.country}
      </p>
      <p>
        <strong>State:</strong> {item.state}
      </p>
      <p>
        <strong>Zip:</strong> {item.zip}
      </p>
      <p>
        <strong>Fullz:</strong> {item.fullz}
      </p>
      <p>
        <strong>Refund:</strong> {item.refund}
      </p>
      <p>
        <strong>Base:</strong> {item.base}
      </p>
      <p>
        <strong>Price:</strong> ${item.price}
      </p>
      <button
        onClick={() => handleAddToCart()}
        style={{
          marginTop: 10,
          padding: 7,
          alignItems: "center",
          backgroundColor: "green",
          color: "white",
          border: "none",
        }}
      >
        Add to Cart{" "}
        <AiOutlineShoppingCart style={{ alignSelf: "center", fontSize: 12 }} />
      </button>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="fullz-page">
        <h1 className="page-title">Cvv</h1>

        {/* Desktop Table View */}
        <div className="table-container">
          <table className="desktop-table">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Type</th>
                <th>Subtype</th>
                <th>Expire Date</th>
                <th>Name</th>
                <th>Country</th>
                <th>State</th>
                <th>Zip</th>
                <th>Fullz</th>
                <th>Refund</th>
                <th>Base</th>
                <th>Price</th>
                <th>Cart</th>
              </tr>
            </thead>
            <tbody>{shuffledData.map((item) => renderTableRow(item))}</tbody>
          </table>
        </div>

        {/* Mobile Card View */}
        <div className="card-container">
          {shuffledData.map((item) => renderCard(item))}
        </div>
        {modal && <Modal closeModal={closeModal} />}
      </div>
    </DashboardLayout>
  );
};

export default Cvv;
