import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import "../styles/Overview.css"; // Import the CSS file

export default function Overview() {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="overview-container">
        <div style={{ border: "1px solid gray" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 20,
              paddingRight: 20,
              alignItems: "center",
            }}
          >
            <h3 style={{ marginLeft: 10, color: "blue", fontSize: 20 }}>
              Fresh Update & Sale
            </h3>
            <p>Last update 7 hours ago</p>
          </div>

          <div
            style={{
              border: "1px solid gray",
              padding: 30,
            }}
          >
            <p>
              Base name: 0218_WORLD_ZIP
              <br />
              Countries: World
              <br />
              Info: Number, Exp Date, CVV2, , No Address
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_IP_PHONE_ZIP
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DE, FL, GA, IA, ID,
              IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE,
              NH, NJ, NM, NV, NY, OH, OK, OR, PA, SC, SD, TN, TX, UT, VA, WA,
              WI, WV, WY]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, IP
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_SSN_ATM_PIN
              <br />
              Countries: USA [AL, AR, AZ, CA, CO, CT, FL, GA, HI, IA, ID, IL,
              IN, KS, KY, LA, MA, MD, MI, MN, MO, MS, NC, ND, NE, NH, NJ, NM,
              NV, NY, OH, OR, PA, SC, SD, TN, TX, UT, VA, VT, WA, WI]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, SSN, DOB, ATM PIN
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_WORLD
              <br />
              Countries: USA [AL, CA, DC, FL, IL, IN, KY, MD, NV, OR, UT, WI]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, IP
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_DOB_ATM_PIN
              <br />
              Countries: USA [DE, FL, GA, MD, MI, MN, ND, NY, OH, PA, SC, VA,
              WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, SSN, DOB, ATM PIN
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_PHONE_ZIP
              <br />
              Countries: USA
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code, Phone
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_EMAIL_ZIP
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DE, FL, GA, ID, IL,
              IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, NH, NJ,
              NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA,
              WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_DOB_PHONE
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, HI,
              IA, IL, IN, KS, KY, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE,
              NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, VA, VT,
              WA, WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code, DOB
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_ZIP_IP_PHONE
              <br />
              Countries: USA [AL, AR, AZ, CA, CO, CT, DC, FL, GA, IA, ID, IL,
              IN, KS, KY, LA, MA, MD, MI, MN, MO, MS, NC, ND, NJ, NV, NY, OH,
              OR, PA, RI, SC, SD, TX, VA, VT, WA, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code, IP
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_PHONE_EMAIL
              <br />
              Countries: USA [AL, AZ, CA, CO, CT, DC, FL, GA, IA, ID, IL, IN,
              KS, LA, MD, MI, MN, MO, MS, NC, NE, NJ, NM, NY, OH, OR, PA, SC,
              TN, TX, VA, VT, WA, WI]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_PL
              <br />
              Countries: USA [DC, MS, MT, NY, PA]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, Last Paid Amount
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_JP
              <br />
              Countries: Japan
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code, Phone
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_PHONE_ZIP_EMAIL
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, FL, GA, HI, IA,
              IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE,
              NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, TN, TX, UT, VA, VT, WA,
              WI, WY]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_PHONE_IP
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, HI,
              IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MT, NC, ND,
              NE, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA,
              VT, WA, WI]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, IP
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_EMAIL_ZIP_PHONE
              <br />
              Countries: USA [AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, ID, IL,
              IN, LA, MA, MD, MI, MS, NC, ND, NE, NJ, NM, NY, OH, OK, OR, PA,
              RI, SC, TN, TX, UT, VA, WA, WI, WY]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_IP_EMAL_ZIP_PHONE
              <br />
              Countries: USA [AL, AZ, CA, CO, CT, DE, FL, GA, IA, IL, IN, KY,
              LA, MA, MD, ME, MI, MN, MS, NC, NH, NJ, NV, NY, OH, OR, PA, RI,
              SC, TN, TX, UT, VA, WA, WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, IP, User Agent
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_IP_EMAIL_PHONE_ZIP
              <br />
              Countries: USA [CA, CO, FL, GA, HI, ID, IL, IN, KY, LA, MA, MD,
              ME, MI, MN, MO, MS, MT, NC, ND, NJ, NM, NV, NY, OH, OK, OR, PA,
              RI, SC, TN, TX, UT, VA, VT, WA, WI]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, IP
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_PHONE_IP_ZIP
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, FL, GA, IA, ID,
              IL, IN, KY, LA, MA, MI, MN, MO, MS, MT, NC, NE, NH, NJ, NM, NV,
              NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV,
              WY]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, IP, User Agent
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_SSN_DOB_DLN
              <br />
              Countries: USA [AL, AR, AZ, CA, CO, CT, DE, FL, GA, HI, IA, ID,
              IL, IN, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, NJ, NM,
              NV, NY, OH, OK, OR, PA, SC, SD, TN, TX, UT, VA, WA, WI, WY]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, Email password, IP, SSN, MMN, DOB, Driver License
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_DOB_PHONE_UA
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, FL, GA, HI, IA,
              ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND,
              NE, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT,
              VA, VT, WA, WI, WV, WY]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code, DOB,
              User Agent
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 1218_US_DOB_PHONE_ZIP
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, HI,
              IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MP, MS, MT,
              NC, ND, NE, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX,
              UT, VA, VT, WA, WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code, DOB,
              User Agent
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_PHONE_EMAIL_ZIP
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, FL, GA, HI, IA, IL,
              IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NJ, NY, OH,
              OK, PA, RI, SC, TN, TX, UT, VA, WA, WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email
              <br />
              Valid rate: 90%
              <br />
              No replacements!
            </p>
            <p>
              Base name: 0218_US_EMAIL_IP_PHONE
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, HI,
              IA, ID, IL, IN, KS, KY, LA, MA, MD, MI, MN, MO, MS, MT, NC, NE,
              NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA,
              VT, WA, WI, WV, WY]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, IP, User Agent
              <br />
              Valid rate: 70%
            </p>
            <p>
              Base name: 0218_US_IP_EMAIL_ZIP
              <br />
              Countries: USA [AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, HI, IA,
              ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, NC, ND, NE,
              NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA,
              VT, WA, WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, IP
              <br />
              Valid rate: 70%
            </p>
            <p>
              Base name: 0218_US_IP_EMAIL
              <br />
              Countries: USA [AK, AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, HI,
              IA, ID, IL, IN, KS, KY, LA, MA, MD, MI, MN, MO, MS, NC, NJ, NM,
              NV, NY, OH, OK, OR, PA, SC, SD, TN, TX, UT, VA, WA, WI, WV]
              <br />
              Info: Number, Exp Date, CVV2, Name, Address, City, Zip code,
              Phone, Email, IP
              <br />
              Valid rate: 70%
            </p>
            <p>
              Base name: Supernova
              <br />
              Countries: USA, World
              <br />
              Info: TR2, TR1+TR2 with state, city, zip
              <br />
              Valid rate: 90%
              <br />
              No Replacements for 24 hours.
            </p>
            <p>
              Base name: Hyatt
              <br />
              Countries: USA [NY], World
              <br />
              Info: TR2, TR1+TR2 with state, city, zip
              <br />
              Valid rate: 90%
              <br />
              No Replacements for 24 hours.
            </p>
            <p>
              Base name: Chill
              <br />
              Countries: USA [CA, IA, NJ, NY, PA, TX], World
              <br />
              Info: TR2, TR1+TR2 with state, city, zip
              <br />
              Valid rate: 90%
              <br />
              No Replacements for 24 hours.
            </p>
            <p>
              Base name: ‎Garfield
              <br />
              Countries: USA [AL, AR, AZ, CA, CO, FL, GA, IN, KS, MN, MO, NC,
              ND, NM, NV, OK, PA, SC, TN, TX, UT, VA, WA], World
              <br />
              Info: TR2, TR1+TR2 with state, city, zip
              <br />
              Valid rate: 90%
              <br />
              No Replacements for 24 hours.
            </p>
            <p>
              Base name: Cabbage
              <br />
              Countries: USA, World
              <br />
              Info: TR2, TR1+TR2 with state, city, zip
              <br />
              Valid rate: 90%
              <br />
              No Replacements for 24 hours.
            </p>
            <p>
              Base name: 0218_FULLZ_SSN_DOB_ZIP
              <br />
              Info: Name, Address, Phone, Email, SSN, DOB
            </p>
            <p>
              Base name: 0218_FULLZ_SSN_DOB_PHONE_DLN
              <br />
              Info: Name, Address, Phone, Email, SSN, DOB, Drivers License
              Number, DL State
            </p>
            <p>
              Base name: 0218_FULLZ_SSN_DOB_DLN
              <br />
              Info: Name, Address, Phone, Email, SSN, DOB, Drivers License
              Number
            </p>
            <p>
              Base name: 0218_FULLZ_SSN_DOB
              <br />
              Info: Name, Address, Phone, Email, SSN, DOB
            </p>
            <p>
              Base name: 0218_FULLZ_CREDIT_REPORT_DLN
              <br />
              Info: Name, Address, Phone, Email, SSN, DOB, Drivers License
              Number, Credit Report
            </p>
            <p>
              Base name: 0218_FULLZ_CREDIT_REPORT_DLSTATE_EMAIL
              <br />
              Info: Name, Address, Phone, Email, SSN, DOB, Drivers License
              Number, Credit Report
            </p>
            <p>
              Base name: 0218_FULLZ_CREDIT_REPORT_EMAIL
              <br />
              Info: Name, Address, Phone, Email, SSN, DOB, Drivers License
              Number, Credit Report
            </p>
            <p>
              SALE! Prices of the bases 0801_SG, 1122_TW were decreased to 25%!
            </p>
            <p>Stay safe & healthy, good luck!</p>
            <p>
              P.S. next update will be released tomorrow at 8 AM Eastern Time
            </p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
