import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Lottery.css"; // Custom CSS for styling
import DashboardLayout from "../components/DashboardLayout";
import Modal from "../components/Modal";

const Lottery = () => {
  const navigation = useNavigate();
  const [modal, setModal] = useState(false);

  const handleAddToCart = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    navigation("/deposit");
  };
  const terms = [
    "Lottery is being played once a week on each Monday at 9 PM Eastern Time.",
    "Price of a lottery ticket is 5$. Tickets cannot be refunded, replaced, there is no money back.",
    "The winners are randomly & automatically selected from the range of others who bought tickets.",
    "Lottery Prize:",
    "First place winner is getting 30% of the jackpot",
    "Second place gets 20% of the jackpot",
    "Third place is getting 10% of the jackpot",
    "Fourth to 25th place winners will split the remaining 40% evenly between each other",
    "Maximum of 5 tickets per customer can be purchased.",
    "Minimum of $100 in total deposits in order to buy lottery tickets.",
    "You've got to have recent purchases for the last two weeks in order to buy tickets.",
    "New Lottery is starting 24 hours after the current jackpot has been played.",
  ];

  const lotteryDetails = [
    { label: "Lottery started:", value: "2024-11-16, 1:45 a.m." },
    { label: "Jackpot played on:", value: "2024-11-23, 9 p.m." },
    { label: "Participants:", value: "230" },
    { label: "Jackpot:", value: "$1150.00" },
    { label: "First place prize:", value: "$345.00" },
    { label: "Second place prize:", value: "$230.00" },
    { label: "Third place prize:", value: "$115.00" },
    { label: "From 4th to 25th place prize:", value: "$20.91" },
  ];

  const winners = [
    {
      place: "#1",
      ticket: "#2024-11-03 - 32392",
      date: "2024-11-01, 9:21 p.m.",
      prize: "$849.00",
    },
    {
      place: "#2",
      ticket: "#2024-11-03 - 33913",
      date: "2024-10-30, 8:36 p.m.",
      prize: "$566.00",
    },
    {
      place: "#3",
      ticket: "#2024-11-03 - 33427",
      date: "2024-11-02, 9:28 a.m.",
      prize: "$283.00",
    },
    {
      place: "#4",
      ticket: "#2024-11-03 - 33166",
      date: "2024-10-31, 3:08 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#5",
      ticket: " #2024-11-03 - 32866",
      date: "2024-10-27, 11:32 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#6",
      ticket: "#2024-11-03 - 32407",
      date: "2024-11-02, 12:53 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#7",
      ticket: "#2024-11-03 - 33232",
      date: "2024-10-29, 10:39 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#8",
      ticket: "#2024-11-03 - 32716",
      date: "2024-11-01, 4:30 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#9",
      ticket: "#2024-11-03 - 33052",
      date: "2024-10-24, 10:48 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#10",
      ticket: "#2024-11-03 - 32986",
      date: "2024-10-22, 4:01 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#11",
      ticket: "#2024-11-03 - 33016",
      date: "2024-11-02, 12:23 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#12",
      ticket: "#2024-11-03 - 33850",
      date: "2024-10-20, 8:25 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#13",
      ticket: " #2024-11-03 - 32398",
      date: "2024-10-22, 10:45 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#14",
      ticket: "#2024-11-03 - 32824",
      date: "2024-11-01, 4:53 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#15",
      ticket: "#2024-11-03 - 33721",
      date: "2024-10-23, 6:51 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#16",
      ticket: " #2024-11-03 - 33247",
      date: "2024-10-27, 12:01 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#17",
      ticket: "#2024-11-03 - 33553",
      date: "2024-10-20, 6:06 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#18",
      ticket: "#2024-11-03 - 33031",
      date: " 2024-10-29, 8:31 p.m",
      prize: "$ 51.45",
    },
    {
      place: "#19",
      ticket: "#2024-11-03 - 32635",
      date: "2024-10-24, 2:37 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#20",
      ticket: "#2024-11-03 - 33730",
      date: "2024-10-27, 8:02 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#21",
      ticket: " #2024-11-03 - 33265",
      date: "2024-12-06, 1:28 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#22",
      ticket: "#2024-11-03 - 32362",
      date: "2024-11-02, 12:51 p.m.",
      prize: "$ 51.45",
    },
    {
      place: "#23",
      ticket: "#2024-11-03 - 32347",
      date: "2024-10-23, 9:34 a.m.",
      prize: "$ 51.45",
    },
    {
      place: "#24",
      ticket: "#2024-11-03 - 33004",
      date: " 2024-10-26, 8:40 a.m.",
      prize: "$ 51.45",
    },
    // Add the remaining winners here...
    {
      place: "#25",
      ticket: "#2024-11-03 - 33376",
      date: "2024-11-02, 10 p.m.",
      prize: "$51.45",
    },
  ];

  return (
    <DashboardLayout>
      <div className="lottery-container">
        {/* Header Section */}
        <div className="lottery-header">
          <h2>Terms & Conditions to play the Lottery:</h2>
        </div>

        {/* Terms & Conditions Section */}
        <div className="lottery-terms">
          <ol>
            {terms.map((term, index) => (
              <li key={index}>{term}</li>
            ))}
          </ol>
        </div>

        <button
          onClick={() => handleAddToCart()}
          style={{
            marginTop: 10,
            padding: 10,
            alignItems: "center",
            backgroundColor: "blue",
            color: "white",
            border: "none",
            fontSize: 15,
          }}
        >
          Buy a ticket 5.00$
        </button>

        {/* Lottery Details Table */}
        <div className="lottery-details">
          <h2>Lottery Details</h2>
          <table>
            <tbody>
              {lotteryDetails.map((detail, index) => (
                <tr key={index}>
                  <td>
                    <strong>{detail.label}</strong>
                  </td>
                  <td>{detail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Winners Table */}
        <div className="lottery-winners">
          <h2>Winners of the Lottery "2024-11-03"</h2>
          <table>
            <tbody>
              {winners.map((winner, index) => (
                <tr key={index}>
                  <td>
                    <strong>Place:</strong> {winner.place}
                  </td>
                  <td>
                    <strong>Ticket:</strong> {winner.ticket}
                  </td>
                  <td>
                    <strong>Date Purchased:</strong> {winner.date}
                  </td>
                  <td>
                    <strong>Prize:</strong> {winner.prize}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {modal && <Modal closeModal={closeModal} />}
      </div>
    </DashboardLayout>
  );
};

export default Lottery;
