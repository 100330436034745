import React, { useState, useEffect } from "react";
import "../styles/AdminPayment.css";
import axios from "axios";
import api from "../components/BackendApi";
import { FaEdit } from "react-icons/fa";
import EditMethodModal from "../components/EditMethodModal";
import AdminLayout from "../components/AdminLayout";

const AdminPayment = () => {
  const [method, setMethod] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const fetchMethod = async () => {
      try {
        const response = await axios.get(`${api}/allMethod`);
        setMethod(response.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchMethod();
  }, []);

  const handleEditClick = (method) => {
    setSelected(method);
  };

  const closeModal = () => {
    setSelected(null);
  };

  return (
    <AdminLayout>
      <div className="adHomeMain" style={{ minHeight: 500 }}>
        <div className="paymentManagementHeader">
          <h2 style={{ color: "black" }}>Deposit Methods</h2>
        </div>
        <div className="methods-container">
          <div className="card-container">
            {method.map((data) => (
              <div
                style={{
                  backgroundColor: "#253745",
                  padding: "20px",
                  borderRadius: 10,
                  border: "2px solid goldenrod",
                  marginTop: 5,
                }}
                key={data._id}
              >
                <div className="adHome211">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 style={{ fontSize: 20, color: "white" }}>
                      {data.name}
                    </h3>
                    <FaEdit
                      onClick={() => handleEditClick(data)}
                      style={{
                        cursor: "pointer",
                        fontSize: 25,
                        color: "white",
                        alignSelf: "center",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {selected && (
            <EditMethodModal method={selected} closeModal={closeModal} />
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminPayment;
