import React, { useState } from "react";
import "../styles/Modal.css";
import { IoClose } from "react-icons/io5";

const GiftModal = ({ closeModal }) => {
  const conditions = [
    "You made a purchase in the last 24 hours",
    "You made a deposit within the last 24 hours",
    "You don't have any outstanding credits/loans",
    "Minimum of $100 in combined deposits",
  ];

  const disclaimer = `Daily free dump is an expiring, randomly picked dump from the older databases. 
      There's no guarantee it will work or not be region locked in your area. Creating a ticket complaining 
      about a free dump will void your ability to receive any future daily gifts in my shop.`;

  const [error, setError] = useState(false);

  const openError = () => {
    setError(true);
  };

  return (
    <div className="modal">
      <div className="modal-content" style={{ height: 350 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Terms for receiving a free daily dump!</h2>

          <IoClose
            style={{
              fontSize: 25,
              alignSelf: "center",
              color: "gray",
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </div>
        <div>
          <hr />
          {error && (
            <div style={{ backgroundColor: "#dc9393", padding: 5 }}>
              <p style={{ color: "#551717" }}>
                In order to receive a gift you've got to have minimum of $100 in
                combined deposits
              </p>
            </div>
          )}

          {/* Conditions Section */}
          <div className="conditions-section">
            <ul>
              {conditions.map((condition, index) => (
                <li key={index}>{condition}</li>
              ))}
            </ul>
          </div>

          {/* Disclaimer Section */}
          <div className="disclaimer-section">
            <h3>Disclaimer:</h3>
            <p>{disclaimer}</p>
          </div>
          <hr />
          <button
            onClick={openError}
            style={{
              marginTop: 10,
              padding: 7,
              float: "right",
              backgroundColor: "#41879e",
              color: "white",
              border: "none",
            }}
          >
            Click here to recieve gift
          </button>
        </div>
      </div>
    </div>
  );
};

export default GiftModal;
