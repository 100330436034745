import React from "react";
import DashboardLayout from "../components/DashboardLayout";

const Education = () => {
  return (
    <DashboardLayout>
      <h1>Education Blog, Knowledge Base, Tutorials and Guides</h1>
      <p>Different check results and region locks</p>
    </DashboardLayout>
  );
};

export default Education;
