import React, { useEffect, useState } from "react";
import AdminLayout from "../components/AdminLayout";
import axios from "axios";
import api from "../components/BackendApi";

const AdminLocation = () => {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${api}/allLocation`);
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchLocations();
  }, []);

  return (
    <AdminLayout>
      <div style={styles.container}>
        <h2 style={styles.title}>Location Details</h2>

        <div style={styles.cardContainer}>
          {locations.map((location, index) => (
            <div key={index} style={styles.card}>
              <h2 style={styles.cardTitle}>IP: {location.ip}</h2>
              <p style={styles.cardText}>
                <strong>Country:</strong> {location.country}
              </p>
              <p style={styles.cardText}>
                <strong>Region:</strong> {location.region}
              </p>
              <p style={styles.cardText}>
                <strong>City:</strong> {location.city}
              </p>
              <p style={styles.cardText}>
                <strong>Time:</strong>{" "}
                {new Date(location.time).toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

// Inline CSS for styling
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    minHeight: "100vh",
  },
  title: {
    fontSize: "17px",
    marginBottom: "20px",
    color: "#333",
  },
  loadingText: {
    textAlign: "center",
    fontSize: "1.5rem",
    color: "#666",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  },
  card: {
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    maxWidth: "300px",
    width: "100%",
    padding: "15px",
    textAlign: "left",
    transition: "transform 0.2s",
  },
  cardTitle: {
    fontSize: "1.2rem",
    color: "#000",
    marginBottom: "10px",
  },
  cardText: {
    margin: "5px 0",
    fontSize: "0.9rem",
    lineHeight: "1.4",
    color: "#555",
  },
};

export default AdminLocation;
