import React from "react";
import DashboardLayout from "../components/DashboardLayout";

const Wholsesale = () => {
  return (
    <DashboardLayout>
      {" "}
      <div className="wholesale-page">
        <h1 className="wholesale-title">Wholesale</h1>
        <div className="wholesale-content">
          <div className="inactive-account-message">
            <p>
              We have only dumps packages. Bulk mix packages are sold as is.
              <br /> Low prices mean you have no ability to replace or refund
              any package contents.
            </p>
          </div>
          <ul className="wholesale-list">
            <li>CVV2</li>
            <li>Dumps By Bases</li>
            <li>Dumps By States</li>
            <li>Dumps By Bases & States</li>
            <li>Dumps By Types, Codes & States</li>
          </ul>
          <p className="wholesale-note">
            Bases for wholesale are not available.
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Wholsesale;
