import React from "react";
import DashboardLayout from "../components/DashboardLayout";

const FAQ = () => {
  const faqs = [
    {
      question: "What do you sell?",
      answer: `I sell the dumps (track2/track1) with country, state, city, zip information (optional) and CVV2 cards. 
                   Also, I provide checking service on the Tools page.`,
    },
    {
      question: "How to top-up my balance?",
      answer: `Please open the Billing Page and choose your preferred method.
                   At this moment I accept: Bitcoin, Paymer, Cryptocheck, WesternUnion, and Moneygram.
                   How to get started with Bitcoin: [https://bitcoin.org/en/getting-started](https://bitcoin.org/en/getting-started).
                   Please bear in mind that not activated accounts for more than 5 days will be deleted automatically.`,
    },
    {
      question: "How long is Bitcoin processing?",
      answer: `We accept Bitcoin payments after 2 confirmations.
                   Confirmations on the Bitcoin network can take from 20 minutes up to 1 day if you send it without a fee.
                   You can always check your transaction here: [https://blockchain.info](https://blockchain.info). 
                   More details about confirmations: [https://en.bitcoin.it/wiki/Confirmation](https://en.bitcoin.it/wiki/Confirmation).`,
    },
    {
      question: "What is Crab Rating?",
      answer: `The Crab Rating encourages users with better conditions based on spending.
                   For example, deposits of $6400 and refunds of $2500 equal $3900, giving you 4 crabs. 
                   Four crabs give:
                   1. 9% discount on all retail orders
                   2. Ability to see new updates without a wait period.
                   Click for more details about Crab Rating.`,
    },
    {
      question: "Why is my rating going down (or up)?",
      answer: `The system calculates the summary of deposits and refunds over the last 60 days. 
                   Lower deposits or higher refunds cause your rating to drop. 
                   Click for more details about Crab Rating.`,
    },
    {
      question: "How can I refund a bad card?",
      answer: `Refunds are possible only via your Orders Page:
                   1. Open your Orders Page.
                   2. Check a bad card.
                   For invalid cards, refunds are immediate. 
                   No refunds via tickets. Cards marked "VALID[T]" are non-refundable.`,
    },
    {
      question: "Do you have an updates schedule?",
      answer: "Yes, updates are released daily.",
    },
    {
      question: "Where can I read or leave feedback about you?",
      answer: `Official threads are available on crdclub.ws, omerta.wf, and verified (TOR).
                   Leave feedback and get a $20 bonus if you have more than 10 posts.`,
    },
    {
      question: "Do you automatically check my cards?",
      answer: `No, only you can check purchased cards. Cards marked "VALID[T]" indicate refund time has expired.`,
    },
    {
      question: "Do you remove my items on the Cart and Orders pages?",
      answer: `Yes, items older than 3 days are deleted from the Cart. Orders older than 3 months are deleted.`,
    },
    {
      question: "What does status VALID[T] mean?",
      answer: `This status indicates that the refund time for the card is out. The card is marked as valid and non-refundable.`,
    },
    {
      question: "Do you have ICQ/Jabber/Skype/E-Mail?",
      answer:
        "I do not use instant messengers but am available via shop tickets.",
    },
    {
      question: "What is base '3-Dollars'?",
      answer: `This base includes old dumps at lower prices but has lower validity. Refunds are not acceptable.`,
    },
    {
      question: "What is Track2?",
      answer: `Track2 contains card details in a specific format. Example: 4131863756855927=17091010000018900000.`,
    },
    {
      question: "What is Track1?",
      answer: `Track1 includes cardholder name and more details. Example: %B4430510072400715^GOLIASH/JOHN^180110111348100713000000.`,
    },
    {
      question: "What is BIN?",
      answer: `BIN is a bank identification number. Example: 414709, 601100, 542418.`,
    },
    {
      question: "How to improve account security?",
      answer: `1. Use a strong, unique password. 
                   2. Enable double verification.
                   3. Log out from shared devices.`,
    },
  ];
  return (
    <DashboardLayout>
      {" "}
      <div className="faq-page">
        <h1 className="faq-title">FAQ</h1>
        <div className="faq-items">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <h2 className="faq-question">{faq.question}</h2>
              <p className="faq-answer">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default FAQ;
