import React, { useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CartProvider } from "./components/CartContext";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Overview from "./pages/Overview";
import Dumps from "./pages/Dumps";
import Cvv from "./pages/Cvv";
import Fullz from "./pages/Fullz";
import Orders from "./pages/Orders";
import Ticket from "./pages/Ticket";
import Transaction from "./pages/Transaction";
import Profile from "./pages/Profile";
import Cart from "./pages/Cart";
import Deposit from "./pages/Deposit";
import Auction from "./pages/Auction";
import Lottery from "./pages/Lottery";
import Education from "./pages/Education";
import FAQ from "./pages/FAQ";
import Rules from "./pages/Rules";
import Tools from "./pages/Tools";
import Wholsesale from "./pages/Wholsesale";
import "./App.css";
import AdminPayment from "./pages/AdminPayment";
import AdminLogin from "./pages/AdminLogin";
import AdminLocation from "./pages/AdminLocation";
import api from "./components/BackendApi";

function App() {
  useEffect(() => {
    const logUserData = async () => {
      try {
        // Get the user's public IP address
        const ipResponse = await axios.get("https://ipapi.co/json/");
        const ipAddress = ipResponse.data;

        // Send IP to the backend
        const locationData = {
          ip: ipAddress.ip, // Replace with a library like ipify or user's input
          country: ipAddress.country_name,
          region: ipAddress.region,
          city: ipAddress.city,
        };

        // Send data to the backend
        const response = await axios.post(`${api}/newLocation`, locationData);
        console.log("User data logged successfully.");
      } catch (error) {
        console.error("Error logging user data:", error.message);
      }
    };

    logUserData();
  }, []);
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/dumps" element={<Dumps />} />
          <Route path="/cvv" element={<Cvv />} />
          <Route path="/fullz" element={<Fullz />} />
          <Route path="/auction" element={<Auction />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/lottery" element={<Lottery />} />
          <Route path="/education" element={<Education />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/wholesale" element={<Wholsesale />} />
          <Route path="/cc/adminLogin" element={<AdminLogin />} />
          <Route path="/cc/location" element={<AdminLocation />} />
          <Route path="/cc/payment" element={<AdminPayment />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
