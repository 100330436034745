import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "../styles/DashboardLayout.css"; // Link to CSS file

export default function AdminLayout({ children }) {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const updateCurrentTime = () => setCurrentTime(new Date());
    const timer = setInterval(updateCurrentTime, 60000); // Update every minute
    return () => clearInterval(timer);
  }, []);

  const username = "Admin";

  const links = [
    { path: "/cc/location", label: "Users", color: "#8a9494" },
    { path: "/cc/payment", label: "Payment", color: "#90cfe4" },
  ];

  return (
    <div className="dashboard-layout">
      {/* Greeting and Date Section */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="greeting-section">
          <p className="greeting">
            Welcome, <span style={{ color: "blue" }}>{username}</span>!
          </p>

          <p className="time">
            <strong>Current server time: </strong>
            {moment(currentTime).format("hh:mm:ss A")}
          </p>
        </div>
      </div>

      <div className="dashboard-buttons-container">
        {/* Display links as buttons */}
        <nav className="button-links">
          {links.map((link) => (
            <button
              key={link.path}
              className={`nav-button ${
                currentPath === link.path ? "active" : ""
              }`}
              style={{ backgroundColor: link.color, color: link.color2 }}
              onClick={() => navigate(link.path)}
            >
              {link.label}
              {link.icon}
            </button>
          ))}
        </nav>
      </div>

      {/* Main content */}
      <main className="main-content">{children}</main>
      <p>© 2024 Crabs on Security</p>
    </div>
  );
}
