import React from "react";
import DashboardLayout from "../components/DashboardLayout";

const Rules = () => {
  return (
    <DashboardLayout>
      {" "}
      <div className="rules-page">
        <h1 className="rules-title">Terms of Service & Replace Policy</h1>
        <div className="rules-content">
          <ol className="rules-list">
            <li>
              By registering and using the shop & services, you indicate that
              you have read and accept these Terms and agree to abide by and be
              bound by all such Terms.
            </li>
            <li>
              We only sell dumps and CVV2 cards, fulls, and look-up services.
            </li>
            <li>
              Any customer is able to reach customer service by submitting a
              ticket.
            </li>
            <li>
              As a long-time player with vast reputation and rating on all
              carding communities, we are not providing any free tests.
            </li>
            <li>
              Account balance in shop can only be spent, there is no money back,
              only refunds of current purchases. Competently plan how much money
              you are willing to spend.
            </li>
            <li>
              Creating clone accounts, attempting to hack, cheat, or abuse the
              shop will lead to an immediate ban.
            </li>
            <li>
              Non-refundable purchases marked in{" "}
              <span className="highlight-red">RED</span> indicate the inability
              to receive any future refunds for this particular card.
            </li>
            <li>
              <strong>Dumps Replacement Policy:</strong>
              <ul className="nested-list">
                <li>
                  We refund the following response codes: DECLINE (05),
                  HOLD-CALL (04, 07, 41, 43), EXPIRED CARD (54), OTHER: 01, 14,
                  15, 51, 62, 78 CODE. *
                </li>
                <li>Approved dumps cannot be refunded.</li>
                <li>
                  We cannot guarantee or control account balance on cards we
                  sell. Valid cards with low balance are non-refundable.
                </li>
                <li>
                  Refunds are only performed via the Orders Page ("Check" button
                  is available next to all refundable purchases).
                </li>
                <li>
                  Default replacement time is 3 hours (if purchased less than 20
                  dumps).
                </li>
                <li>
                  Replacement times based on percentage of replaced dumps:
                  <ul>
                    <li>0-10%: 48 hours *</li>
                    <li>11-15%: 24 hours *</li>
                    <li>16-25%: 12 hours *</li>
                    <li>26-35%: 6 hours *</li>
                    <li>36-50%: 1 hour *</li>
                    <li>More than 60%: no replacements *</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <strong>CVV2 Replacement Policy:</strong>
              <ul className="nested-list">
                <li>
                  We refund the following response codes: DECLINE (05),
                  HOLD-CALL (04, 07, 41, 43), EXPIRED CARD (54), OTHER: 01, 14,
                  15, 51, 62, 78, 82, EA, EB, N7 CODE. *
                </li>
                <li>Approved cards cannot be refunded.</li>
                <li>
                  We cannot guarantee or control account balance on cards we
                  sell. Valid cards with low balance are non-refundable.
                </li>
                <li>
                  Replacements are only performed via the Orders Page ("Check"
                  button is available next to all refundable purchases).
                </li>
                <li>
                  Default replacement time is 1 hour (if purchased less than 20
                  cards).
                </li>
                <li>
                  Replacement times based on percentage of replaced cards:
                  <ul>
                    <li>0-10%: 12 hours *</li>
                    <li>11-15%: 6 hours *</li>
                    <li>16-25%: 3 hours *</li>
                    <li>26-35%: 2 hours *</li>
                    <li>36-50%: 1 hour *</li>
                    <li>More than 50%: no replacements *</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Please note that Brian's Club is not a cloud service to store all
              your purchases forever. Save your orders locally to your computer,
              hand-held device, smartphone, notepad, or write it down on a
              cocktail napkin. Customer's orders are removed after 6 months of
              purchase for security reasons!
            </li>
          </ol>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Rules;
