import React, { useState } from "react";
import "../styles/Modal.css";
import { IoClose } from "react-icons/io5";

const CarbModal = ({ closeModal }) => {
  const userStats = {
    totalDeposits: 0.0,
    totalRefunds: 0.0,
    crabPoints: 0.0,
    crabRating: 0,
    nextLevel: 1,
    pointsLeft: 1.0,
  };

  const crabRatings = [
    {
      points: "< 1",
      rating: "0 crabs",
      discounts: "-",
      other: "wait period 3 hours",
    },
    {
      points: "1 - 1000",
      rating: "1 crab",
      discounts: "3%",
      other: "wait period 2 hours",
    },
    {
      points: "1001 - 2000",
      rating: "2 crabs",
      discounts: "5%",
      other: "wait period 1 hour",
    },
    { points: "2001 - 3000", rating: "3 crabs", discounts: "7%", other: "-" },
    { points: "3001 - 4000", rating: "4 crabs", discounts: "9%", other: "-" },
    { points: "4001 - 6000", rating: "5 crabs", discounts: "12%", other: "-" },
    {
      points: "> 6001",
      rating: "super crab",
      discounts: "15%",
      other: "VIP status",
    },
  ];

  return (
    <div className="modal">
      <div className="modal-content" style={{ height: "85%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Crab Rating</h2>
          <IoClose
            style={{
              fontSize: 25,
              alignSelf: "center",
              color: "gray",
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </div>
        <div>
          <hr />
          <div className="user-stats">
            <p>
              <strong>Total Deposits:</strong> $
              {userStats.totalDeposits.toFixed(2)}
            </p>
            <p>
              <strong>Total Refunds:</strong> $
              {userStats.totalRefunds.toFixed(2)}
            </p>
            <p>
              <strong>Crab Points:</strong> {userStats.crabPoints.toFixed(2)} -{" "}
              {userStats.crabPoints.toFixed(2)} ={" "}
              {userStats.crabPoints.toFixed(2)}
            </p>
            <p>
              <strong>Your Crab Rating:</strong> {userStats.crabRating}
            </p>
            <p>
              <strong>Next Level:</strong> {userStats.nextLevel} crab (
              {userStats.pointsLeft.toFixed(2)} points left)
            </p>
            <p>We count only deposits and refunds from the last 60 days.</p>
            <p>The rating is recalculated every 30 minutes.</p>
          </div>

          {/* Crab Ratings Table */}
          <div className="crab-ratings">
            <table>
              <thead>
                <tr>
                  <th>Crab Points</th>
                  <th>Rating</th>
                  <th>Discounts</th>
                  <th>Other</th>
                </tr>
              </thead>
              <tbody>
                {crabRatings.map((rating, index) => (
                  <tr key={index}>
                    <td>{rating.points}</td>
                    <td>{rating.rating}</td>
                    <td>{rating.discounts}</td>
                    <td>{rating.other}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <hr />
          <button
            onClick={closeModal}
            style={{
              marginTop: 10,
              padding: 7,
              float: "right",
              backgroundColor: "#41879e",
              color: "white",
              border: "none",
            }}
          >
            close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarbModal;
