import React, { useState } from "react";
import "../styles/Modal.css";
import { IoClose } from "react-icons/io5";

const Modal = ({ closeModal }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 style={{ color: "red" }}>Sorry! Your Account is Inactive</h2>
          <IoClose
            style={{
              fontSize: 25,
              alignSelf: "center",
              color: "gray",
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </div>
        <div>
          <hr />
          <p>
            Your account is currently inactive. For activation you need to top
            up your balance with the minimum amount of <strong> $50</strong>
          </p>
          <p>
            {" "}
            This activation sum will be added to your balance, so you won't lose
            a cent
          </p>
          <p> Your account will be activated automatically after payment</p>
          <p> They will be added to your balance automatically</p>
          <p> NB: this operation takes about 10 - 15 minutes</p>
          <hr />
          <button
            onClick={closeModal}
            style={{
              marginTop: 10,
              padding: 7,
              float: "right",
              backgroundColor: "#41879e",
              color: "white",
              border: "none",
            }}
          >
            Thanks, i got it
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
