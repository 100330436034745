import React, { useState } from "react";
import DashboardLayout from "../components/DashboardLayout";

const Tools = () => {
  const [reason, setReason] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic for handling the form submission
    console.log({ reason, subject, description });
  };
  return (
    <DashboardLayout>
      {" "}
      <div className="ticket-container">
        <h2 className="ticket-title">Tools</h2>
        <form className="ticket-form" onSubmit={handleSubmit}>
          <label htmlFor="reason" className="ticket-label">
            Checker
          </label>
          <select
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="ticket-select"
          >
            <option value="Technical Issue">LuxChecker(0.50$/check)</option>
            <option value="Billing Inquiry">0Check(0.50$/check)</option>
            <option value="General Support">Card-OK(0.50$/check)</option>
          </select>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="ticket-textarea"
            placeholder="445668892093930"
            required
          ></textarea>

          <button type="submit" className="ticket-button">
            Check
          </button>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default Tools;
