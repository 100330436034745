import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import { AiOutlineShoppingCart } from "react-icons/ai";
import fullzData from "../components/FullzData.json";
import Modal from "../components/Modal";

const Fullz = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [shuffledData, setShuffledData] = useState([]);

  useEffect(() => {
    const shuffleArray = (array) => {
      let shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };

    setShuffledData(shuffleArray(fullzData));
  }, []);

  const handleAddToCart = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    navigate("/deposit");
  };

  const renderTableRow = (item) => (
    <tr key={item.id} className="table-row">
      <td>{item.name}</td>
      <td>{item.cityZipCountry}</td>
      <td>{item.fullAddress}</td>
      <td>{item.phone}</td>
      <td>{item.email}</td>
      <td>{item.rentOwn}</td>
      <td>{item.yearsResidence}</td>
      <td>{item.incomeType}</td>
      <td>{item.employer}</td>
      <td>{item.occupation}</td>
      <td>{item.yearsEmployed}</td>
      <td>{item.workPhone}</td>
      <td>{item.netIncome}</td>
      <td>{item.creditReport}</td>
      <td>{item.creditCard}</td>
      <td>{item.checkingAccount}</td>
      <td>{item.ssn}</td>
      <td>{item.dob}</td>
      <td>{item.mmn}</td>
      <td>{item.driversLicense}</td>
      <td>{item.account}</td>
      <td>{item.routing}</td>
      <td>{item.base}</td>
      <td>${item.price}</td>
      <td>
        <button
          onClick={handleAddToCart}
          style={{
            marginTop: 10,
            padding: 7,
            alignItems: "center",
            backgroundColor: "green",
            color: "white",
            border: "none",
          }}
        >
          Add to Cart{" "}
          <AiOutlineShoppingCart
            style={{ alignSelf: "center", fontSize: 12 }}
          />
        </button>
      </td>
    </tr>
  );

  const renderCard = (item) => (
    <div key={item.id} className="card">
      <p>
        <strong>Name:</strong> {item.name}
      </p>
      <p>
        <strong>City/Zip/Country:</strong> {item.cityZipCountry}
      </p>
      <p>
        <strong>Full Address:</strong> {item.fullAddress}
      </p>
      <p>
        <strong>Phone:</strong> {item.phone}
      </p>
      <p>
        <strong>Email:</strong> {item.email}
      </p>
      <p>
        <strong>Rent/Own:</strong> {item.rentOwn}
      </p>
      <p>
        <strong>Years of Residence:</strong> {item.yearsResidence}
      </p>
      <p>
        <strong>Income Type:</strong> {item.incomeType}
      </p>
      <p>
        <strong>Employer:</strong> {item.employer}
      </p>
      <p>
        <strong>Occupation:</strong> {item.occupation}
      </p>
      <p>
        <strong>Years Employed:</strong> {item.yearsEmployed}
      </p>
      <p>
        <strong>Work Phone:</strong> {item.workPhone}
      </p>
      <p>
        <strong>Net Monthly Income:</strong> {item.netIncome}
      </p>
      <p>
        <strong>Credit Report:</strong> {item.creditReport}
      </p>
      <p>
        <strong>Credit Card:</strong> {item.creditCard}
      </p>
      <p>
        <strong>Checking Account:</strong> {item.checkingAccount}
      </p>
      <p>
        <strong>SSN:</strong> {item.ssn}
      </p>
      <p>
        <strong>DOB:</strong> {item.dob}
      </p>
      <p>
        <strong>MMN:</strong> {item.mmn}
      </p>
      <p>
        <strong>Driver's License:</strong> {item.driversLicense}
      </p>
      <p>
        <strong>Account:</strong> {item.account}
      </p>
      <p>
        <strong>Routing:</strong> {item.routing}
      </p>
      <p>
        <strong>Base:</strong> {item.base}
      </p>
      <p>
        <strong>Price:</strong> ${item.price}
      </p>
      <button
        onClick={handleAddToCart}
        style={{
          marginTop: 10,
          padding: 7,
          alignItems: "center",
          backgroundColor: "green",
          color: "white",
          border: "none",
        }}
      >
        Add to Cart{" "}
        <AiOutlineShoppingCart style={{ alignSelf: "center", fontSize: 12 }} />
      </button>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="fullz-page">
        <h1 className="page-title">Fullz</h1>

        {/* Desktop Table View */}
        <div className="table-container">
          <table className="desktop-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>City/Zip/Country</th>
                <th>Full Address</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Rent/Own</th>
                <th>Years of Residence</th>
                <th>Income Type</th>
                <th>Employer</th>
                <th>Occupation</th>
                <th>Years Employed</th>
                <th>Work Phone</th>
                <th>Net Income</th>
                <th>Credit Report</th>
                <th>Credit Card</th>
                <th>Checking Account</th>
                <th>SSN</th>
                <th>DOB</th>
                <th>MMN</th>
                <th>Driver's License</th>
                <th>Account</th>
                <th>Routing</th>
                <th>Base</th>
                <th>Price</th>
                <th>Cart</th>
              </tr>
            </thead>
            <tbody>{shuffledData.map((item) => renderTableRow(item))}</tbody>
          </table>
        </div>

        {/* Mobile Card View */}
        <div className="card-container">
          {shuffledData.map((item) => renderCard(item))}
        </div>
        {modal && <Modal closeModal={closeModal} />}
      </div>
    </DashboardLayout>
  );
};

export default Fullz;
